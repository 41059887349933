import React from "react";
import { mapping } from "../style/mapping";
import { Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import TextareaAutosize from "react-textarea-autosize";

//-----------------------------------------------------------------------------
// Field props:
// title
// hint
// type

const FieldStyle = {
    height: mapping["Field_Height"],
    width: mapping["Field_Width"],
    borderWidth: "0px",
    backgroundColor: mapping["Field_BG_Color"],
    border: "none !important",
    //outline:mapping['Field_Outline']+' solid '+borderColor,
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    color: mapping["Color_Basic_Light"]
};
const bottomMargining = {
    marginBottom: "20px"
};
const errorMessageText = {
    position: "absolute",
    paddingTop: "2px",
    color: mapping["Color_Basic_Danger"]
};
const titleStyle = {
    ...mapping["forms/label/1-default"],
    ...mapping["Form_Label_Padding"],
    display: "flex",
    paddingBottom: "9px",
    color: mapping["Color_Basic_Light"]
};

class FieldInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            hint: this.props.hint,
            type: this.props.type
        };
    }

    render() {
        return (
            <div>
                <span
                    style={Object.assign(
                        {},
                        mapping["forms/input-default"],
                        mapping["Form_Label_Padding"],
                        { display: "flex", paddingBottom: "9px" }
                    )}
                >
                    {this.state.title}
                </span>
                <div style={mapping["Form_Bottom_Margin"]}>
                    <Field name={"firstName"} label={"First Name"} />
                    <input
                        name={this.props.name}
                        placeholder={this.state.hint}
                        style={Object.assign(
                            {},
                            mapping["forms/input-default"],
                            FieldStyle
                        )}
                        type={this.props.type}
                        onChange={this.props.onChange}
                    />
                </div>
            </div>
        );
    }
}

const ValidationInput = fieldRenderProps => {
    const { validationMessage, visited, title, ...others } = fieldRenderProps;
    var inputStyle = FieldStyle;
    if (visited && validationMessage) {
        var inputStyle = {
            ...FieldStyle,
            boxShadow: "0 0 0 2px rgb(220,53,69)"
        };
    } else if (visited) {
        var inputStyle = {
            ...FieldStyle,
            boxShadow: "0 0 0 2px rgb(53,220 ,69)"
        };
    }

    return (
        <div>
            <span style={titleStyle}>{title}</span>
            <Input style={inputStyle} {...others} />
            {visited && validationMessage && (
                <div
                    style={Object.assign(
                        {},
                        mapping["paragraph/tiny/lightleft"],
                        errorMessageText
                    )}
                >
                    {validationMessage}
                </div>
            )}
            <div style={bottomMargining} />
        </div>
    );
};

const NonValidationInput = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        title,
        width,
        ...others
    } = fieldRenderProps;
    let inputStyle = fieldRenderProps.fieldstyle
        ? fieldRenderProps.fieldstyle
        : FieldStyle;
    if (width) {
        inputStyle = {
            height: mapping["Field_Height"],
            width: width,
            borderWidth: "0px",
            backgroundColor: mapping["Field_BG_Color"],
            border: "none !important",
            //outline:mapping['Field_Outline']+' solid '+borderColor,
            paddingLeft: mapping["Field_padding_Horizontal"],
            paddingRight: mapping["Field_padding_Horizontal"],
            color: mapping["Color_Basic_Light"]
        };
    }
    let inputTitleStyle = fieldRenderProps.TitleStyle
        ? fieldRenderProps.TitleStyle
        : titleStyle;

    return (
        <div>
            {title && <span style={inputTitleStyle}>{title}</span>}
            <Input style={{ ...inputStyle }} {...others} />
        </div>
    );
};

class NonValidationTextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.props.value) {
            if (this.props.CustomizedOnChange) {
                this.props.CustomizedOnChange(nextProps.value);
            }

            if (this.props.onChange) {
                this.props.onChange({ value: nextProps.value });
            }
        }
    }
    handleChange = event => {
        var textareaLineHeight = this.props.lineheight
            ? this.props.lineheight
            : 22;
        var minrows = this.props.minrows ? this.props.minrows : 5;
        var maxrows = this.props.maxrows ? this.props.maxrows : 10;

        const previousRows = event.target.rows;
        event.target.rows = minrows; // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxrows) {
            event.target.rows = maxrows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        this.props.changerow(currentRows < maxrows ? currentRows : maxrows);

        if (this.props.onChange) {
            this.props.onChange({ value: event.target.value });
        }
        if (this.props.CustomizedOnChange) {
            this.props.CustomizedOnChange(event.target.value);
        }
    };
    render() {
        const { validationMessage, visited, title, ...others } = this.props;
        var inputStyle = this.props.fieldstyle
            ? this.props.fieldstyle
            : FieldStyle;
        var inputTitleStyle = this.props.TitleStyle
            ? this.props.TitleStyle
            : titleStyle;

        return (
            <div>
                <span style={inputTitleStyle}>{title}</span>
                <textarea
                    className="k-textbox"
                    style={inputStyle}
                    {...others}
                    onChange={this.handleChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

class AutoSizeTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.props.value) {
            if (this.props.CustomizedOnChange) {
                this.props.CustomizedOnChange(nextProps.value);
            }

            if (this.props.onChange) {
                this.props.onChange({ value: nextProps.value });
            }
        }
    }
    handleChange = event => {
        if (this.props.onChange) {
            this.props.onChange({ value: event.target.value });
        }
        if (this.props.CustomizedOnChange) {
            this.props.CustomizedOnChange(event.target.value);
        }
    };
    render() {
        // console.log(this.props.value)
        const { validationMessage, visited, title, ...others } = this.props;
        var inputStyle = this.props.fieldstyle
            ? this.props.fieldstyle
            : FieldStyle;
        var inputTitleStyle = this.props.TitleStyle
            ? this.props.TitleStyle
            : titleStyle;

        return (
            <div>
                {title && <span style={inputTitleStyle}>{title}</span>}
                <TextareaAutosize
                    className={"k-textbox"}
                    style={inputStyle}
                    onChange={this.handleChange}
                    minRows={this.props.minRows}
                    value={this.props.value ? this.props.value : ""}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export {
    FieldInput,
    ValidationInput,
    NonValidationInput,
    NonValidationTextInput,
    AutoSizeTextArea
};
