import React from "react";
import PropTypes from "prop-types";
import { CustomizedFieldInput } from "./TextField";

const iconStyle = {
    color: "white",
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem"
};

class SearchBar extends React.Component {
    render() {
        const { classes } = this.props;
        var hint = this.props.hint ? this.props.hint : "Search...";

        const searchBar = (
            <div style={{ position: "relative" }}>
                <span class="k-icon k-i-search" style={iconStyle}></span>
                <CustomizedFieldInput
                    hint={hint}
                    onChange={this.props.searchCallBack}
                    height={2}
                    width={20}
                />
            </div>
        );

        return <div>{searchBar}</div>;
    }
}

export { SearchBar };
