import React from "react";
import { mapping } from "../style/mapping";
import { Button } from "@progress/kendo-react-buttons";
import "@progress/kendo-theme-bootstrap/dist/all.css";

// available properties:
// disabled bool
// size     string
// color    string

class ButtonRadio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: "default",
            disabled: this.props.disabled || false,
            color:
                mapping["Color_Basic_" + this.props.color] ||
                mapping["Color_Basic_Primary"],
            size: this.props.size || "Default",
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] },
            Height: this.props.height,
            Width: this.props.width
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.disabled || false,
            color:
                mapping["Color_Basic_" + nextProps.color] ||
                mapping["Color_Basic_Primary"],
            size: nextProps.size || "Default"
        };
    }

    hover() {
        this.setState({
            btnState: "hover",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Hover"],
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    reset() {
        this.setState({
            btnState: "default",
            color: this.state.color.substring(0, 7),
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    focus() {
        this.setState({
            btnState: "Focus",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Focus"],
            focusStateBorderStyle: {
                boxShadow: mapping["Button_Border_Focus_Boxshadow"]
            }
        });
    }
    clicked() {
        console.log("clicked");
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render() {
        var paddingStyle = mapping["Button_Padding"];
        //======================== Button style ===================

        var ButtonSize = {
            height: this.state.Height
                ? this.state.Height
                : mapping[this.state.size + "_Button_Height"],
            width: this.state.Width
                ? this.state.Width
                : mapping[this.state.size + "_Button_Width"],
            boxShadow: mapping["Button_boxShadow"]
        };

        var ButtonBorderStyle = {
            borderWidth: mapping[this.state.size + "_Button_Border_Normal"],
            borderRadius: mapping["Round_Button_Radius"],
            paddingRight: mapping["IconBtnGhostInnerPadding"],
            paddingLeft: mapping["IconBtnGhostInnerPadding"],
            paddingTop: "0px",
            paddingBottom: "0px"
        };

        var ButtonColorStyle = { backgroundColor: this.state.color };

        //========================= Button Text Style ========================

        var fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : mapping["Font_Button_" + this.state.size + "_White"];

        //========================= Button Icon Style =========================

        //========================== Special styles ===========================

        if (this.state.disabled) {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Overlay_Disable"]
            };
        } else if (this.props.color === "Link") {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Extra_Transparent"]
            };
            fontStyle = mapping["Font_Button_" + this.state.size + "_Link"];
        } else if (
            this.props.color === "Warning" ||
            this.props.color === "Light"
        ) {
            fontStyle = mapping["Font_Button_" + this.state.size + "_Dark"];
        }

        return (
            <div style={paddingStyle}>
                <Button
                    disabled={this.state.disabled}
                    style={Object.assign(
                        ButtonSize,
                        ButtonBorderStyle,
                        ButtonColorStyle,
                        this.state.focusStateBorderStyle
                    )}
                    onMouseEnter={() => this.hover()}
                    onMouseLeave={() => this.reset()}
                    onMouseDown={() => this.focus()}
                    onMouseUp={() => this.hover()}
                    onClick={() => this.clicked()}
                    type={this.props.type}
                >
                    <span style={fontStyle}>{this.props.name}</span>
                </Button>
            </div>
        );
    }
}

class ButtonSolid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: "default",
            disabled: this.props.disabled || false,
            color:
                mapping["Color_Basic_" + this.props.color] ||
                mapping["Color_Basic_Primary"],
            size: this.props.size || "Default",
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] },
            Height: this.props.height,
            Width: this.props.width
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.disabled || false,
            color:
                mapping["Color_Basic_" + nextProps.color] ||
                mapping["Color_Basic_Primary"],
            size: nextProps.size || "Default"
        };
    }

    hover() {
        this.setState({
            btnState: "hover",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Hover"],
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    reset() {
        this.setState({
            btnState: "default",
            color: this.state.color.substring(0, 7),
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    focus() {
        this.setState({
            btnState: "Focus",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Focus"],
            focusStateBorderStyle: {
                boxShadow: mapping["Button_Border_Focus_Boxshadow"]
            }
        });
    }
    clicked() {
        console.log("clicked");
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render() {
        var paddingStyle = mapping["Button_Padding"];
        //======================== Button style ===================

        var ButtonSize = {
            height: this.state.Height
                ? this.state.Height
                : mapping[this.state.size + "_Button_Height"],
            width: this.state.Width
                ? this.state.Width
                : mapping[this.state.size + "_Button_Width"],
            boxShadow: mapping["Button_boxShadow"]
        };

        var ButtonBorderStyle = {
            borderWidth: mapping[this.state.size + "_Button_Border_Normal"],
            borderRadius: mapping["Round_Button_Radius"],
            paddingRight: mapping["IconBtnGhostInnerPadding"],
            paddingLeft: mapping["IconBtnGhostInnerPadding"],
            paddingTop: "0px",
            paddingBottom: "0px"
        };

        var ButtonColorStyle = { backgroundColor: this.state.color };

        //========================= Button Text Style ========================

        var fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : mapping["Font_Button_" + this.state.size + "_White"];

        //========================= Button Icon Style =========================

        //========================== Special styles ===========================

        if (this.state.disabled) {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Overlay_Disable"]
            };
        } else if (this.props.color === "Link") {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Extra_Transparent"]
            };
            fontStyle = mapping["Font_Button_" + this.state.size + "_Link"];
        } else if (
            this.props.color === "Warning" ||
            this.props.color === "Light"
        ) {
            fontStyle = mapping["Font_Button_" + this.state.size + "_Dark"];
        }

        return (
            <div style={paddingStyle}>
                <Button
                    disabled={this.state.disabled}
                    style={Object.assign(
                        ButtonSize,
                        ButtonBorderStyle,
                        ButtonColorStyle,
                        this.state.focusStateBorderStyle
                    )}
                    onMouseEnter={() => this.hover()}
                    onMouseLeave={() => this.reset()}
                    onMouseDown={() => this.focus()}
                    onMouseUp={() => this.hover()}
                    onClick={() => this.clicked()}
                    type={this.props.type}
                >
                    <span style={fontStyle}>{this.props.name}</span>
                </Button>
            </div>
        );
    }
}

class ButtonGhost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: "default",
            disabled: this.props.disabled || false,
            colorName: this.props.color || "Primary",
            color:
                mapping["Color_Basic_" + this.props.color] ||
                mapping["Color_Basic_Primary"],
            size: this.props.size || "Default",
            Height: this.props.height,
            Width: this.props.width
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.disabled || false,
            color:
                mapping["Color_Basic_" + nextProps.color] ||
                mapping["Color_Basic_Primary"],
            size: nextProps.size || "Default"
        };
    }

    hover() {
        this.setState({
            btnState: "hover",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Hover"]
        });
    }
    reset() {
        this.setState({
            btnState: "default",
            color: this.state.color.substring(0, 7)
        });
    }
    focus() {
        this.setState({
            btnState: "Focus",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Focus"]
        });
    }
    clicked() {
        console.log("clicked");
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render() {
        var paddingStyle = mapping["Button_Padding"];
        //======================== Button style ===================

        var ButtonSize = {
            height: this.state.Height
                ? this.state.Height
                : mapping[this.state.size + "_Button_Height"],
            width: this.state.Width
                ? this.state.Width
                : mapping[this.state.size + "_Button_Width"],
            boxShadow: mapping["Button_boxShadow"]
        };

        var ButtonBorderStyle = {
            borderWidth: mapping["Outline_Button_Border"],
            borderRadius: mapping["Round_Button_Radius"],
            paddingRight: mapping["IconBtnGhostInnerPadding"],
            paddingLeft: mapping["IconBtnGhostInnerPadding"],
            paddingTop: "0px",
            paddingBottom: "0px"
        };

        var ButtonBorderColorStyle = { borderColor: this.state.color };

        var ButtonBackgroundColor = {
            backgroundColor: mapping["Color_Extra_Transparent"]
        };

        //========================= Button Text Style ========================

        let mappingName =
            "Font_Button_" + this.state.size + "_" + this.state.colorName;
        let fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : mapping[mappingName];

        //========================= Button Icon Style =========================

        //========================== Special styles ===========================

        if (this.state.disabled) {
            ButtonBorderColorStyle = {
                borderColor: mapping["Color_Overlay_Disable"]
            };
            fontStyle = mapping["Font_Button_" + this.state.size + "_Mute"];
        }

        //========================= Button Icon Style =========================

        return (
            <div style={paddingStyle}>
                <Button
                    disabled={this.state.disabled}
                    style={Object.assign(
                        ButtonSize,
                        ButtonBorderStyle,
                        ButtonBackgroundColor,
                        ButtonBorderColorStyle
                    )}
                    onMouseEnter={() => this.hover()}
                    onMouseLeave={() => this.reset()}
                    onMouseDown={() => this.focus()}
                    onMouseUp={() => this.hover()}
                    onClick={() => this.clicked()}
                    type={this.props.type}
                >
                    <span style={fontStyle}>{this.props.name}</span>
                </Button>
            </div>
        );
    }
}

class IconButtonSolid extends React.Component {
    constructor(props) {
        //console.log(props.disabled)
        //console.log(props.name)
        super(props);
        this.state = {
            btnState: "default",
            disabled: this.props.disabled || false,
            color:
                mapping["Color_Basic_" + this.props.color] ||
                mapping["Color_Basic_Primary"],
            size: this.props.size || "Default",
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] },
            Height: this.props.height,
            Width: this.props.width
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.disabled || false,
            color:
                mapping["Color_Basic_" + nextProps.color] ||
                mapping["Color_Basic_Primary"],
            size: nextProps.size || "Default"
        };
    }

    hover() {
        this.setState({
            btnState: "hover",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Hover"],
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    reset() {
        this.setState({
            btnState: "default",
            color: this.state.color.substring(0, 7),
            focusStateBorderStyle: { boxShadow: mapping["Button_boxShadow"] }
        });
    }
    focus() {
        this.setState({
            btnState: "Focus",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Focus"],
            focusStateBorderStyle: {
                boxShadow: mapping["Button_Border_Focus_Boxshadow"]
            }
        });
    }
    clicked() {
        console.log("clicked");
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render() {
        var paddingStyle = mapping["Button_Padding"];
        //======================== Button style ===================

        var ButtonSize = {
            height: this.state.Height
                ? this.state.Height
                : mapping[this.state.size + "_Button_Height"],
            width: this.state.Width
                ? this.state.Width
                : mapping[this.state.size + "_Button_Width"]
        };

        var ButtonBorderStyle = {
            borderWidth:
                mapping["Icon_" + this.state.size + "_Button_Border_Normal"],
            borderRadius: mapping["Round_Button_Radius"],
            paddingRight: mapping["IconBtnGhostInnerPadding"],
            paddingLeft: mapping["IconBtnGhostInnerPadding"],
            paddingTop: "0px",
            paddingBottom: "0px"
        };

        var ButtonColorStyle = { backgroundColor: this.state.color };

        //========================= Button Text Style ========================

        var fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : mapping["Font_Button_" + this.state.size + "_White"];

        //========================= Button Icon Style =========================

        var buttonIconStyle = {
            color: mapping["Icon_Button_color"],
            fontSize: mapping["Icon_Button_Icon_" + this.state.size]
        };

        //========================== Special styles ===========================

        if (this.state.disabled) {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Overlay_Disable"]
            };
        } else if (this.props.color === "Link") {
            ButtonColorStyle = {
                backgroundColor: mapping["Color_Extra_Transparent"]
            };
            fontStyle = mapping["Font_Button_" + this.state.size + "_Link"];
            buttonIconStyle.color = mapping["Color_Basic_Link"];
        } else if (
            this.props.color === "Warning" ||
            this.props.color === "Light"
        ) {
            fontStyle = mapping["Font_Button_" + this.state.size + "_Dark"];
            buttonIconStyle.color = mapping["Color_Basic_Dark"];
        }

        return (
            <div style={paddingStyle}>
                <Button
                    disabled={this.state.disabled}
                    style={Object.assign(
                        ButtonSize,
                        ButtonBorderStyle,
                        ButtonColorStyle,
                        this.state.focusStateBorderStyle,
                        { justifyContent: "space-between" }
                    )}
                    onMouseEnter={() => this.hover()}
                    onMouseLeave={() => this.reset()}
                    onMouseDown={() => this.focus()}
                    onMouseUp={() => this.hover()}
                    onClick={() => this.clicked()}
                    type={this.props.type}
                >
                    {this.props.iconPos !== "Right" ? this.props.icon : null}
                    <span style={fontStyle}>{this.props.name}</span>
                    {this.props.iconPos === "Right" ? this.props.icon : null}
                </Button>
            </div>
        );
    }
}

class IconButtonGhost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: "default",
            disabled: this.props.disabled || false,
            colorName: this.props.color || "Primary",
            color:
                mapping["Color_Basic_" + this.props.color] ||
                mapping["Color_Basic_Primary"],
            size: this.props.size || "Default",
            Height: this.props.height,
            Width: this.props.width
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.disabled || false,
            color:
                mapping["Color_Basic_" + nextProps.color] ||
                mapping["Color_Basic_Primary"],
            size: nextProps.size || "Default"
        };
    }

    hover() {
        this.setState({
            btnState: "hover",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Hover"]
        });
    }
    reset() {
        this.setState({
            btnState: "default",
            color: this.state.color.substring(0, 7)
        });
    }
    focus() {
        this.setState({
            btnState: "Focus",
            color:
                this.state.color.substring(0, 7) +
                mapping["Color_Overlay_Focus"]
        });
    }
    clicked() {
        console.log("clicked");
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render() {
        var paddingStyle = mapping["Button_Padding"];
        //======================== Button style ===================

        var ButtonSize = {
            height: this.state.Height
                ? this.state.Height
                : mapping[this.state.size + "_Button_Height"],
            width: this.state.Width
                ? this.state.Width
                : mapping[this.state.size + "_Button_Width"],
            boxShadow: mapping["Button_boxShadow"]
        };

        var ButtonBorderStyle = {
            borderWidth: mapping["Outline_Button_Border"],
            borderRadius: mapping["Round_Button_Radius"],
            paddingRight: mapping["IconBtnGhostInnerPadding"],
            paddingLeft: mapping["IconBtnGhostInnerPadding"],
            paddingTop: "0px",
            paddingBottom: "0px"
        };

        var ButtonBorderColorStyle = { borderColor: this.state.color };

        var ButtonBackgroundColor = {
            backgroundColor: mapping["Color_Extra_Transparent"]
        };

        //========================= Button Text Style ========================

        let fontMappingName =
            "Font_Button_" + this.state.size + "_" + this.state.colorName;
        var fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : mapping[fontMappingName];

        //========================= Button Icon Style =========================

        var buttonIconStyle = {
            color: this.state.color,
            fontSize: mapping["Icon_Button_Icon_" + this.state.size]
        };

        //========================== Special styles ===========================

        if (this.state.disabled) {
            ButtonBorderColorStyle = {
                borderColor: mapping["Color_Overlay_Disable"]
            };
            fontStyle = mapping["Font_Button_" + this.state.size + "_Mute"];
        }

        return (
            <div style={paddingStyle}>
                <Button
                    disabled={this.state.disabled}
                    style={Object.assign(
                        ButtonSize,
                        ButtonBorderStyle,
                        ButtonBorderColorStyle,
                        ButtonBackgroundColor,
                        { justifyContent: "space-between" }
                    )}
                    onMouseEnter={() => this.hover()}
                    onMouseLeave={() => this.reset()}
                    onMouseDown={() => this.focus()}
                    onMouseUp={() => this.hover()}
                    onClick={() => this.clicked()}
                    type={this.props.type}
                >
                    {this.props.iconPos !== "Right" ? this.props.icon : null}
                    <span style={fontStyle}>{this.props.name}</span>
                    {this.props.iconPos === "Right" ? this.props.icon : null}
                </Button>
            </div>
        );
    }
}

export { ButtonSolid, ButtonGhost, IconButtonSolid, IconButtonGhost };
