import React from "react";
import { mapping } from "../style/mapping";
import { SmallAvatar } from "./Avatar";

class NameCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const UserContentStyle = {
            display: "inline-flex",
            paddingBottom: "1.25rem",
            ...this.props.UserContentStyle
        };
        return (
            <div style={UserContentStyle}>
                <SmallAvatar
                    size={"2.5rem"}
                    initial={this.props.initial}
                    pic={this.props.pic}
                />
                <div style={UserTextWrapperStyle}>
                    <div
                        style={
                            this.props.nameStyle
                                ? this.props.nameStyle
                                : UserNameStyle
                        }
                    >
                        {this.props.name}
                    </div>
                    <div
                        style={
                            this.props.roleStyle
                                ? this.props.roleStyle
                                : UserRoleStyle
                        }
                    >
                        {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}

const UserTextWrapperStyle = {
    display: "inline-block",
    marginLeft: "0.4rem"
};
const UserNameStyle = {
    ...mapping["paragraph/default/lightleft"],
    paddingTop: "0.2rem"
};

const UserRoleStyle = {
    ...mapping["Font_Avatar_Role"],
    paddingTop: "0.2rem"
};

export { NameCard };
