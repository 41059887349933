import { mapping } from "./mapping";

var navbarStyle = {
    backgroundStyle: {
        backgroundColor: mapping["Color_BG_01_solid"],
        height: "60px",
        width: "120rem",
        display: "flex",
        position: "relative"
    },
    userBlockIconButton: {
        padding: "0px",
        backgroundColor: "#00000000",
        outline: "none",
        border: "none"
    },
    userBlockStyle: {
        height: "60px",
        position: "absolute",
        right: "0px"
    },
    userBlockIconStyle: {
        color: "white",
        fontSize: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "10px",
        marginRight: "10px"
    },
    userBlockAvatarStyle: {
        display: "inline-flex",
        marginLeft: "10px",
        marginRight: "10px"
    }
};
export { navbarStyle };
