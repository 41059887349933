import React from "react";
import { ButtonSolid } from "./Button";
import { mapping } from "../style/mapping";
class ConfirmationPopup extends React.Component {
    render() {
        const iconWrapperStyle = {
            transform: "scale(" + this.props.size + ")",
            transformOrigin: "50% 50% 0px",
            display: "inline-flex",
            ...this.props.wrapperStyle
        };
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div style={ConfirmationFormWrapStyle}>
                <div style={ConfirmationFormPositionStyle}>
                    <div style={ConfirmationFormStyle}>
                        <div
                            style={{
                                ...mapping["heading/h3/lightleft"],
                                padding: "25px 20px 22px 20px"
                            }}
                        >
                            {this.props.title}
                        </div>
                        <div style={separationLineStyle} />
                        <div
                            style={{
                                ...mapping["heading/h5/lightleft"],
                                padding: "27px 20px 42px 20px"
                            }}
                        >
                            {this.props.content}
                        </div>

                        <div>
                            <div style={buttonWrapper}>
                                <ButtonSolid
                                    name={"Cancel"}
                                    clickEvent={this.props.cancelFunction}
                                />
                                <ButtonSolid
                                    name={"Confirm"}
                                    clickEvent={this.props.confirmFunction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ConfirmationFormStyle = {
    height: "fit-content",
    width: "621px",
    boxShadow: "0 6px 20px 0 rgba(0,0,0,0.4)",
    backgroundColor: "#283A5D"
};

const ConfirmationFormPositionStyle = {
    position: "relative",
    left: "-50%"
};
const ConfirmationFormWrapStyle = {
    position: "fixed",
    left: "50%",
    top: "13.3rem"
};
const separationLineStyle = {
    boxSizing: "border-box",
    height: "1px",
    width: "621px",
    border: "1px solid #777171"
};

const buttonWrapper = {
    display: "inline-flex",
    width: "100%",
    padding: "20px",
    justifyContent: "space-between"
};
export { ConfirmationPopup };
