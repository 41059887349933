import React from "react";
import { mapping } from "../style/mapping";
import { ButtonSolid } from "./Button";

// STYLES

class RiskEditCardAddNew extends React.Component {
    render() {
        return (
            <div style={CardStyle}>
                <div style={CardButtonStyle}>
                    <ButtonSolid
                        name={this.props.buttonName}
                        size={"Small"}
                        height={"1.5rem"}
                        width={this.props.width ? this.props.width : "10rem"}
                        clickEvent={this.props.clickEvent}
                    />
                </div>
            </div>
        );
    }
}
const CardStyle = {
    display: "relative",
    height: "5rem",
    width: "65.875rem",
    backgroundColor: mapping["Color_BG_02_solid"],
    boxShadow: "2px 2px 10px rgb(41,50,61)" // X axis, Y axis ,blur, colour
};

const CardButtonStyle = {
    width: "fit-content",
    margin: "auto",
    paddingTop: "1.75rem"
};
export { RiskEditCardAddNew };
