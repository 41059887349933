import * as data from "./style-data.json";

var fallbackColor = "#00FF00";
var fallbackTypeStyles = { fontSize: "99px" };
// following function is the parser for the JSON file downloaded from DSM
// input : [Category,Name,SubName], *SubName is only required for color
// output: the JSON style sheet.
function getlist(para) {
    var list = data.list;
    var resultList;
    if (para.Category === "colors") {
        if (para.SubName === "") {
            console.log(
                "mapping function has bug, color mapping lack of SubName, name to fix"
            );
            return fallbackColor;
        }
        list = list.colors;
        for (var index in list) {
            if (list[index].name === para.Name) {
                resultList = list[index].colors;
                break;
            }
        }
        for (var index1 in resultList) {
            if (resultList[index1].name === para.SubName) {
                resultList = resultList[index1].value;
                break;
            }
        }
        if (!resultList) {
            return fallbackColor;
        }
    } else if (para.Category === "typeStyles") {
        if (para.SubName) {
            console.log(
                "mapping function has bug, typeStyles got unexpected SubName param, name to fix"
            );
            return fallbackTypeStyles;
        }
        list = list.typeStyles;
        for (var index2 in list) {
            if (list[index2].name === para.Name) {
                resultList = list[index2];
                break;
            }
        }
        if (!resultList) {
            return fallbackTypeStyles;
        }
    }
    return resultList;
}

var local_style = {
    // ==================================== size ============================================

    // ------------------------------------  Button -----------------------------------------
    Button_boxShadow: "0 0 0 0 rgb(0,0,0,0)",

    Default_Button_Height: "38px",
    Default_Button_Width: "69px",
    Default_Button_Radius: "5px",
    Default_Button_Border_Normal: "0px",

    Small_Button_Height: "31px",
    Small_Button_Width: "59px",
    Small_Button_Radius: "3px",
    Small_Button_Border_Normal: "0px",

    Large_Button_Height: "48px",
    Large_Button_Width: "88px",
    Large_Button_Radius: "3px",
    Large_Button_Border_Normal: "0px",

    Badge_Button_Height: "18px",
    Badge_Button_Width: "48px",
    Badge_Button_Radius: "3px",
    Badge_Button_Border_Normal: "0px",

    Icon_Default_Button_Height: "38px",
    Icon_Default_Button_Width: "84px",
    Icon_Default_Button_Radius: "5px",
    Icon_Default_Button_Border_Normal: "0px",

    Icon_Small_Button_Height: "31px",
    Icon_Small_Button_Width: "80px",
    Icon_Small_Button_Radius: "3px",
    Icon_Small_Button_Border_Normal: "0px",

    Icon_Large_Button_Height: "48px",
    Icon_Large_Button_Width: "100px",
    Icon_Large_Button_Radius: "3px",
    Icon_Large_Button_Border_Normal: "0px",

    Icon_Badge_Button_Height: "18px",
    Icon_Badge_Button_Width: "60px",
    Icon_Badge_Button_Radius: "3px",
    Icon_Badge_Button_Border_Normal: "0px",

    Icon_Button_Icon_Default: "16px",
    Icon_Button_Icon_Small: "14px",
    Icon_Button_Icon_Large: "18px",
    Icon_Button_Icon_Badge: "9px",

    Outline_Button_Border: "3px",
    Outline_Button_padding: ".2rem",

    Button_Border_Focus_Boxshadow: "0 0 0 1px #30C2FFB2",

    // ------------------------------------  Avatar -----------------------------------------

    Small_Avatar_Radius: "48px",

    Large_Avatar_Radius: "96px",

    // ------------------------------------   Form   -----------------------------------------

    Field_Height: "40px",
    Field_Width: "280px",
    Field_Outline: "3px",
    Field_padding_Horizontal: "10px",
    Form_Bottom_Margin: { marginBottom: "20px" },
    Form_Label_Padding: { paddingBottom: "10px" },

    // -------------------------------------   Risk Matrix -----------------------------------
    RM_Block_Size: "60px",
    RM_Risk_Number_Size: "22px",
    RM_Block_Margin: "6px",
    RM_RIsk_Number_Margin: "3px",
    RM_Block_Radius: "4px",
    RM_Risk_Number_Radius: "2px",

    // =================================== Colors  ============================================

    // ---------------------------------   General ---------------------------------------------

    Color_Basic_Background: "#232C3D",
    topBannerColor: "#1b2634",
    ComponentBGColor: "#000000B2",
    Color_Extra_Half_Transparent: "#000000B2",
    Color_Extra_Popup_BG: "#00000099",
    Color_Extra_Transparent: "#00000000",
    Color_Card_Shadow: "#0000342F",

    // ----------------------------------   Button   -----------------------------------------
    Icon_Button_color: "white",

    Color_Overlay_Hover: "E5",
    Color_Overlay_Focus: "B2",
    Color_Overlay_Disable: "rgba(153, 153, 153, 1)",

    // ------------------------------------  Form  --------------------------------------------
    Field_BG_Color: "#6c757d30",
    Color_Field_BG_Lighter: "#F8F9FA33",

    Color_DropDownButton_BG_Lighter: "#F8F9FA19",
    Color_DropDownButton_BG_Lighter_Disable: "#F8F9FA09",
    // ------------------------------------  RiskMatrix --------------------------------------

    Color_Risk_Tower_Inactive_Transparent: "80",
    Color_Risk_Tower_Active: "#2F3B52",
    Color_Risk_Tile_Background: "#151A24",
    Color_Risk_Tower_Hover_Shadow: "0 2px 20px 0 rgba(0,0,0,0.3)",
    Color_Risk_Tile_Hover_Background: "#000000",
    Color_Risk_Tile_Hover_Border: "#0094FF",

    Color_Parent_Risk_Count_Background: "#BA8FF733",

    // ===================================== padding  =========================================
    // -------------------------------------  button  -----------------------------------------
    // 'Button_Padding': {padding:'10px',height:'fit-content',width:'fit-content', display:'inline-block'},
    IconBtnGhostInnerPadding: "0.5rem",

    // -------------------------------------  Tab --------------------------------------------

    //--------------------------------------  Font -------------------------------------------
    Font_Avatar_Role: {
        height: "14px",
        width: "90px",
        color: "#5BC0DE",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: 0,
        lineHeight: "14px"
    },
    Font_Version_Name: {
        height: "17px",
        width: "131px",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: 0,
        lineHeight: "10px"
    },
    Font_Version_Date: {
        height: "13px",
        width: "58px",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "10px",
        fontStyle: "italic",
        letterSpacing: "0",
        lineHeight: "13px"
    },
    Font_Version_Status: {
        height: "13px",
        width: "58px",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "13px"
    },
    Font_Summary_Detail_Row: {
        height: "20px",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "-0.23px",
        lineHeight: "20px",
        textAlign: "left"
    },
    // -------------------------------------   new color 6/9 -----------------------------------
    Color_KRI_Middle_Background: "#EAA958",
    Color_Tab_Unselected_Font: "#86909A"
};

function updateStyle() {
    fetch(
        "https://kpmg-sg.invisionapp.com/dsm-export/kpmg-sg/aim-dsm/style-data.json?exportFormat=list&key=ryEWSzxe8"
    )
        .then(res => {
            return res.json();
        })
        .then(json => {
            return json;
        });
    return data;
}

var mapping = {
    //===================================== meta function ====================================
    update: updateStyle(),
    // ==================================== size ============================================

    // =================================== Colors  ============================================

    Color_Basic_Primary: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Primary"
    }),
    Color_Basic_Secondary: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Secondary"
    }),
    Color_Basic_Success: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Success"
    }),
    Color_Basic_Danger: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Danger"
    }),
    Color_Basic_Warning: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Warning"
    }),
    Color_Basic_Info: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Info"
    }),
    Color_Basic_Light: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Light"
    }),
    Color_Basic_Dark: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Dark"
    }),
    Color_Basic_Mute: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Mute"
    }),
    Color_Basic_Mute_2: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Mute 2"
    }),
    Color_Basic_Link: getlist({
        Category: "colors",
        Name: "Basic",
        SubName: "Link"
    }),

    Color_Text_Warning: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Warning Text"
    }),
    Color_Text_Success: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Success Text"
    }),
    Color_Text_Danger: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Danger Text"
    }),
    Color_Text_Info: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Info Text"
    }),
    Color_Text_Primary: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Primary Text"
    }),
    Color_Text_SolidDark: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Solid Dark Text"
    }),
    Color_Text_Mute: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Mute Text"
    }),
    Color_Text_Dark: getlist({
        Category: "colors",
        Name: "Text Colours",
        SubName: "Dark Text"
    }),

    Color_Platform_RiskHigh: getlist({
        Category: "colors",
        Name: "Platform Specific",
        SubName: "Risk High"
    }),
    Color_Platform_RiskMed: getlist({
        Category: "colors",
        Name: "Platform Specific",
        SubName: "Risk Med"
    }),
    Color_Platform_RiskLow: getlist({
        Category: "colors",
        Name: "Platform Specific",
        SubName: "Risk Low"
    }),
    Color_Platform_Accent1: getlist({
        Category: "colors",
        Name: "Platform Specific",
        SubName: "Accent 1"
    }),

    Color_BG_01_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "01-bg-solid"
    }),
    Color_BG_02_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "02-bg-solid"
    }),
    Color_BG_03_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "03-bg-solid"
    }),
    Color_BG_03_trans: getlist({
        Category: "colors",
        Name: "Background Transparent",
        SubName: "03-bg-trans"
    }),
    Color_BG_04_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "04-bg-solid"
    }),
    Color_BG_05_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "05-bg-solid"
    }),
    Color_BG_06_solid: getlist({
        Category: "colors",
        Name: "Background Solid",
        SubName: "06-bg-solid"
    }),

    Color_Form_01_Field_Normal: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "01-field-normal"
    }),
    Color_Form_00_Field_Solid: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "00-field-solid"
    }),
    Color_Form_02_Field_Active: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "02-field-active"
    }),
    Color_Form_03_Field_Error: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "03-field-error"
    }),
    Color_Form_04_Field_Success: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "04-field-success"
    }),
    Color_Form_05_Field_Disabled: getlist({
        Category: "colors",
        Name: "Form Styles",
        SubName: "05-field-disabled"
    }),

    // Fallback color
    Color_FallBack: getlist({
        Category: "colors",
        Name: "FallBack",
        SubName: "FallBack"
    }),

    // =================================== TypeStyles  ============================================

    Font_Button_Default_White: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/0-white (for solids)"
    }),
    Font_Button_Default_Primary: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/1-primary"
    }),
    Font_Button_Default_Secondary: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/2-secondary"
    }),
    Font_Button_Default_Success: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/3-success"
    }),
    Font_Button_Default_Danger: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/4-danger"
    }),
    Font_Button_Default_Warning: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/5-warning"
    }),
    Font_Button_Default_Info: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/6-info"
    }),
    Font_Button_Default_Light: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/7-light"
    }),
    Font_Button_Default_Dark: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/8-dark"
    }),
    Font_Button_Default_Link: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/9-link"
    }),
    Font_Button_Default_Mute: getlist({
        Category: "typeStyles",
        Name: "button fonts/default/10-Mute"
    }),
    Font_Button_Large_White: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/0-white (for solids)"
    }),
    Font_Button_Large_Primary: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/1-primary"
    }),
    Font_Button_Large_Secondary: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/2-secondary"
    }),
    Font_Button_Large_Success: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/3-success"
    }),
    Font_Button_Large_Danger: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/4-danger"
    }),
    Font_Button_Large_Warning: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/5-warning"
    }),
    Font_Button_Large_Info: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/6-info"
    }),
    Font_Button_Large_Light: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/7-light"
    }),
    Font_Button_Large_Dark: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/8-dark"
    }),
    Font_Button_Large_Link: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/9-link"
    }),
    Font_Button_Large_Mute: getlist({
        Category: "typeStyles",
        Name: "button fonts/large/10-Mute"
    }),
    Font_Button_Small_White: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/0-white (for solids)"
    }),
    Font_Button_Small_Primary: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/1-primary"
    }),
    Font_Button_Small_Secondary: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/2-secondary"
    }),
    Font_Button_Small_Success: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/3-success"
    }),
    Font_Button_Small_Danger: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/4-danger"
    }),
    Font_Button_Small_Warning: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/5-warning"
    }),
    Font_Button_Small_Info: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/6-info"
    }),
    Font_Button_Small_Light: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/7-light"
    }),
    Font_Button_Small_Dark: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/8-dark"
    }),
    Font_Button_Small_Link: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/9-link"
    }),
    Font_Button_Small_Mute: getlist({
        Category: "typeStyles",
        Name: "button fonts/small/10-Mute"
    }),
    Font_Button_Badge_White: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/0-white (for solids)"
    }),
    Font_Button_Badge_Primary: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/1-primary"
    }),
    Font_Button_Badge_Secondary: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/2-secondary"
    }),
    Font_Button_Badge_Success: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/3-success"
    }),
    Font_Button_Badge_Danger: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/4-danger"
    }),
    Font_Button_Badge_Warning: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/5-warning"
    }),
    Font_Button_Badge_Info: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/6-info"
    }),
    Font_Button_Badge_Light: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/7-light"
    }),
    Font_Button_Badge_Dark: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/8-dark"
    }),
    Font_Button_Badge_Link: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/9-link"
    }),
    Font_Button_Badge_Mute: getlist({
        Category: "typeStyles",
        Name: "button fonts/badge/10-Mute"
    }),

    "display/d1/lightleft": getlist({
        Category: "typeStyles",
        Name: "display/d1/light left"
    }),
    "display/d1/lightcenter": getlist({
        Category: "typeStyles",
        Name: "display/d1/light center"
    }),
    "display/d1/lightright": getlist({
        Category: "typeStyles",
        Name: "display/d1/light right "
    }),
    "display/d2/lightleft": getlist({
        Category: "typeStyles",
        Name: "display/d2/light left"
    }),
    "display/d2/lightcenter": getlist({
        Category: "typeStyles",
        Name: "display/d2/light center"
    }),
    "display/d2/lightright": getlist({
        Category: "typeStyles",
        Name: "display/d2/light right "
    }),
    "display/d3/lightleft": getlist({
        Category: "typeStyles",
        Name: "display/d3/light left"
    }),
    "display/d3/lightcenter": getlist({
        Category: "typeStyles",
        Name: "display/d3/light center"
    }),
    "display/d3/lightright": getlist({
        Category: "typeStyles",
        Name: "display/d3/light right "
    }),
    "display/d4/lightleft": getlist({
        Category: "typeStyles",
        Name: "display/d4/light left"
    }),
    "display/d4/lightcenter": getlist({
        Category: "typeStyles",
        Name: "display/d4/light center"
    }),
    "display/d4/lightright": getlist({
        Category: "typeStyles",
        Name: "display/d4/light right"
    }),
    "heading/h1/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h1/light left"
    }),
    "heading/h1/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h1/light center"
    }),
    "heading/h1/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h1/light right "
    }),
    "heading/h1/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h1/muted left"
    }),
    "heading/h1/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h1/muted center"
    }),
    "heading/h1/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h1/muted right"
    }),
    "heading/h2/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h2/light left"
    }),
    "heading/h2/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h2/light center"
    }),
    "heading/h2/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h2/light right "
    }),
    "heading/h2/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h2/muted left"
    }),
    "heading/h2/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h2/muted center"
    }),
    "heading/h2/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h2/muted right "
    }),
    "heading/h3/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h3/light left"
    }),
    "heading/h3/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h3/light center"
    }),
    "heading/h3/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h3/light right "
    }),
    "heading/h3/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h3/muted left"
    }),
    "heading/h3/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h3/muted center"
    }),
    "heading/h3/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h3/muted right"
    }),
    "heading/h4/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h4/light left"
    }),
    "heading/h4/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h4/light center"
    }),
    "heading/h4/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h4/light right"
    }),
    "heading/h4/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h4/muted left"
    }),
    "heading/h4/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h4/muted center"
    }),
    "heading/h4/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h4/muted right "
    }),
    "heading/h5/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h5/light left"
    }),
    "heading/h5/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h5/light center"
    }),
    "heading/h5/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h5/light right "
    }),
    "heading/h5/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h5/muted left"
    }),
    "heading/h5/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h5/muted center"
    }),
    "heading/h5/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h5/muted right"
    }),
    "heading/h6/lightleft": getlist({
        Category: "typeStyles",
        Name: "heading/h6/light left"
    }),
    "heading/h6/lightcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h6/light center"
    }),
    "heading/h6/lightright": getlist({
        Category: "typeStyles",
        Name: "heading/h6/light right"
    }),
    "heading/h6/mutedleft": getlist({
        Category: "typeStyles",
        Name: "heading/h6/muted left"
    }),
    "heading/h6/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h6/muted center"
    }),
    "heading/h6/mutedright": getlist({
        Category: "typeStyles",
        Name: "heading/h6/muted right"
    }),
    "paragraph/lead/lightleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/light left"
    }),
    "paragraph/lead/lightcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/light center"
    }),
    "paragraph/lead/lightright": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/light right"
    }),
    "paragraph/lead/mutedleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/muted left"
    }),
    "paragraph/lead/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/muted center"
    }),
    "paragraph/lead/mutedright": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/muted right "
    }),
    "paragraph/default/lightleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/light left"
    }),
    "paragraph/default/lightcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/light center"
    }),
    "paragraph/default/lightright": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/light right"
    }),
    "paragraph/default/mutedleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/muted left"
    }),
    "paragraph/default/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/muted center"
    }),
    "paragraph/default/mutedright": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/muted right"
    }),
    "paragraph/small/lightleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/light left"
    }),
    "paragraph/small/lightcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/light center"
    }),
    "paragraph/small/lightright": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/light right"
    }),
    "paragraph/small/mutedleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/muted left"
    }),
    "paragraph/small/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/muted center"
    }),
    "paragraph/small/mutedright": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/muted right"
    }),
    "paragraph/tiny/lightcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/light center"
    }),
    "paragraph/tiny/lightright": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/light right"
    }),
    "paragraph/tiny/mutedleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/muted left"
    }),
    "paragraph/tiny/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/muted center"
    }),
    "paragraph/tiny/mutedright": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/muted right"
    }),
    "inlinetext/strong/mutedright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/muted right "
    }),
    "display/d2/darkcenter": getlist({
        Category: "typeStyles",
        Name: "display/d2/dark center"
    }),
    "inlinetext/strong/lightleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/light left"
    }),
    "display/d3/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "display/d3/muted center"
    }),
    "inlinetext/strong/mutedleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/muted left"
    }),
    "display/d4/mutedright": getlist({
        Category: "typeStyles",
        Name: "display/d4/muted right "
    }),
    "heading/h6/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h6/dark left"
    }),
    "inlinetext/strong/darkleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/dark left"
    }),
    "display/d2/mutedright": getlist({
        Category: "typeStyles",
        Name: "display/d2/muted right "
    }),
    "inlinetext/strong/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/muted center"
    }),
    "display/d3/darkright": getlist({
        Category: "typeStyles",
        Name: "display/d3/dark right "
    }),
    "inlinetext/Italicized/lightleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/light left"
    }),
    "heading/h3/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h3/dark left"
    }),
    "inlinetext/Underline/darkleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/dark left"
    }),
    "inlinetext/Underline/mutedleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/muted left"
    }),
    "inlinetext/strike/mutedright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/muted right"
    }),
    "inlinetext/Underline/darkright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/dark right "
    }),
    "display/d3/darkcenter": getlist({
        Category: "typeStyles",
        Name: "display/d3/dark center"
    }),
    "paragraph/small/darkleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/dark left"
    }),
    "paragraph/default/darkright": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/dark right "
    }),
    "display/d1/mutedright": getlist({
        Category: "typeStyles",
        Name: "display/d1/muted right "
    }),
    "inlinetext/strike/darkcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/dark center"
    }),
    "display/d2/darkright": getlist({
        Category: "typeStyles",
        Name: "display/d2/dark right "
    }),
    "paragraph/default/darkcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/dark center"
    }),
    "inlinetext/Italicized/darkleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/dark left"
    }),
    "paragraph/lead/darkright": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/dark right "
    }),
    "inlinetext/Italicized/lightright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/light right "
    }),
    "heading/h3/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h3/dark right "
    }),
    "inlinetext/Italicized/darkcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/dark center"
    }),
    "heading/h2/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h2/dark left"
    }),
    "heading/h1/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h1/dark left"
    }),
    "display/d1/darkright": getlist({
        Category: "typeStyles",
        Name: "display/d1/dark right "
    }),
    "inlinetext/strong/darkright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/dark right "
    }),
    "display/d4/darkleft": getlist({
        Category: "typeStyles",
        Name: "display/d4/dark left"
    }),
    "heading/h4/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h4/dark left"
    }),
    "inlinetext/strike/lightleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/light left"
    }),
    "inlinetext/Italicized/lightcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/light center"
    }),
    "inlinetext/Underline/mutedright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/muted right "
    }),
    "inlinetext/Underline/lightright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/light right "
    }),
    "inlinetext/strike/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/muted center"
    }),
    "heading/h5/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h5/dark center"
    }),
    "display/d2/mutedleft": getlist({
        Category: "typeStyles",
        Name: "display/d2/muted left"
    }),
    "display/d1/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "display/d1/muted center"
    }),
    "heading/h3/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h3/dark center"
    }),
    "paragraph/default/darkleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/default/dark left"
    }),
    "paragraph/tiny/darkleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/dark left"
    }),
    "paragraph/lead/darkleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/dark left"
    }),
    "display/d2/darkleft": getlist({
        Category: "typeStyles",
        Name: "display/d2/dark left"
    }),
    "display/d3/mutedleft": getlist({
        Category: "typeStyles",
        Name: "display/d3/muted left"
    }),
    "paragraph/small/darkright": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/dark right"
    }),
    "paragraph/lead/darkcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/lead/dark center"
    }),
    "display/d4/darkright": getlist({
        Category: "typeStyles",
        Name: "display/d4/dark right "
    }),
    "heading/h2/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h2/dark right "
    }),
    "display/d4/mutedleft": getlist({
        Category: "typeStyles",
        Name: "display/d4/muted left"
    }),
    "display/d3/mutedright": getlist({
        Category: "typeStyles",
        Name: "display/d3/muted right"
    }),
    "inlinetext/Underline/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/muted center"
    }),
    "display/d1/darkcenter": getlist({
        Category: "typeStyles",
        Name: "display/d1/dark center"
    }),
    "display/d1/darkleft": getlist({
        Category: "typeStyles",
        Name: "display/d1/dark left"
    }),
    "heading/h1/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h1/dark right "
    }),
    "heading/h4/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h4/dark center"
    }),
    "display/d4/darkcenter": getlist({
        Category: "typeStyles",
        Name: "display/d4/dark center"
    }),
    "inlinetext/strike/darkright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/dark right"
    }),
    "inlinetext/strike/darkleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/dark left"
    }),
    "inlinetext/strike/lightcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/light center"
    }),
    "heading/h5/darkleft": getlist({
        Category: "typeStyles",
        Name: "heading/h5/dark left"
    }),
    "paragraph/tiny/darkright": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/dark right"
    }),
    "paragraph/tiny/darkcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/dark center"
    }),
    "heading/h4/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h4/dark right "
    }),
    "inlinetext/strong/lightcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/light center"
    }),
    "inlinetext/Underline/lightleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/light left"
    }),
    "display/d1/mutedleft": getlist({
        Category: "typeStyles",
        Name: "display/d1/muted left"
    }),
    "inlinetext/Italicized/mutedleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/muted left"
    }),
    "inlinetext/strike/lightright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/light right"
    }),
    "display/d2/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "display/d2/muted center"
    }),
    "inlinetext/Italicized/darkright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/dark right "
    }),
    "heading/h6/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h6/dark right"
    }),
    "inlinetext/Underline/darkcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/dark center"
    }),
    "inlinetext/Italicized/mutedright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/muted right"
    }),
    "inlinetext/strong/lightright": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/light right"
    }),
    "heading/h5/darkright": getlist({
        Category: "typeStyles",
        Name: "heading/h5/dark right"
    }),
    "inlinetext/strike/mutedleft": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strike/muted left"
    }),
    "inlinetext/Underline/lightcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Underline/light center"
    }),
    "inlinetext/strong/darkcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/strong/dark center"
    }),
    "heading/h1/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h1/dark center"
    }),
    "display/d4/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "display/d4/muted center"
    }),
    "paragraph/small/darkcenter": getlist({
        Category: "typeStyles",
        Name: "paragraph/small/dark center"
    }),
    "display/d3/darkleft": getlist({
        Category: "typeStyles",
        Name: "display/d3/dark left"
    }),
    "heading/h2/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h2/dark center"
    }),
    "heading/h6/darkcenter": getlist({
        Category: "typeStyles",
        Name: "heading/h6/dark center"
    }),
    "inlinetext/Italicized/mutedcenter": getlist({
        Category: "typeStyles",
        Name: "inlinetext/Italicized/muted center"
    }),

    "forms/label/1-default": getlist({
        Category: "typeStyles",
        Name: "forms/label/1-default"
    }),
    "forms/input-default": getlist({
        Category: "typeStyles",
        Name: "forms/input-default"
    }),
    "forms/label/4-disabled": getlist({
        Category: "typeStyles",
        Name: "forms/label/4-disabled"
    }),
    "symbols/navigation/vertical-tabs/active": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/vertical-tabs/active"
    }),
    "symbols/table/body/dark": getlist({
        Category: "typeStyles",
        Name: "symbols/table/body/dark"
    }),
    "forms/helptext/4-muted": getlist({
        Category: "typeStyles",
        Name: "forms/helptext/4-muted"
    }),
    "symbols/badge/light": getlist({
        Category: "typeStyles",
        Name: "symbols/badge/light"
    }),
    "symbols/navigation/tab/inactive": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/tab/inactive"
    }),
    "forms/helptext/2-success": getlist({
        Category: "typeStyles",
        Name: "forms/helptext/2-success"
    }),
    "forms/label/2-success": getlist({
        Category: "typeStyles",
        Name: "forms/label/2-success"
    }),
    "symbols/pagination/pagination-arrows": getlist({
        Category: "typeStyles",
        Name: "symbols/pagination/pagination-arrows"
    }),
    "symbols/alert/8-dark": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/8-dark"
    }),
    "symbols/carousel/heading": getlist({
        Category: "typeStyles",
        Name: "symbols/carousel/heading"
    }),
    "forms/placeholder-large": getlist({
        Category: "typeStyles",
        Name: "forms/placeholder-large"
    }),
    "forms/helptext/3-danger": getlist({
        Category: "typeStyles",
        Name: "forms/helptext/3-danger"
    }),
    "symbols/table/body/light": getlist({
        Category: "typeStyles",
        Name: "symbols/table/body/light"
    }),
    "symbols/badge/dark": getlist({
        Category: "typeStyles",
        Name: "symbols/badge/dark"
    }),
    "symbols/alert/3-success": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/3-success"
    }),
    "forms/placeholder-default": getlist({
        Category: "typeStyles",
        Name: "forms/placeholder-default"
    }),
    "symbols/alert/1-primary": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/1-primary"
    }),
    "forms/input-small": getlist({
        Category: "typeStyles",
        Name: "forms/input-small"
    }),
    "symbols/alert/4-danger": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/4-danger"
    }),
    "symbols/pagination/hover": getlist({
        Category: "typeStyles",
        Name: "symbols/pagination/hover"
    }),
    "forms/label/2-danger": getlist({
        Category: "typeStyles",
        Name: "forms/label/2-danger"
    }),
    "forms/helptext/1-Reversed": getlist({
        Category: "typeStyles",
        Name: "forms/helptext/1-Reversed"
    }),
    "symbols/alert/2-secondary": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/2-secondary"
    }),
    "symbols/modal/modal-title": getlist({
        Category: "typeStyles",
        Name: "symbols/modal/modal-title"
    }),
    "symbols/breadcrumbs/previous": getlist({
        Category: "typeStyles",
        Name: "symbols/breadcrumbs/previous"
    }),
    "symbols/alert/5-warning": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/5-warning"
    }),
    "symbols/navigation/tab/active": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/tab/active"
    }),
    "forms/placeholder-small": getlist({
        Category: "typeStyles",
        Name: "forms/placeholder-small"
    }),
    "forms/input-large": getlist({
        Category: "typeStyles",
        Name: "forms/input-large"
    }),
    "symbols/pagination/default": getlist({
        Category: "typeStyles",
        Name: "symbols/pagination/default"
    }),
    "symbols/navigation/vertical-tabs/inactive": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/vertical-tabs/inactive"
    }),
    "symbols/tooltip": getlist({
        Category: "typeStyles",
        Name: "symbols/tooltip"
    }),
    "symbols/table/header/light": getlist({
        Category: "typeStyles",
        Name: "symbols/table/header/light"
    }),
    "symbols/alert/7-light": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/7-light"
    }),
    "symbols/modal/modal-body": getlist({
        Category: "typeStyles",
        Name: "symbols/modal/modal-body"
    }),
    "symbols/breadcrumbs/active": getlist({
        Category: "typeStyles",
        Name: "symbols/breadcrumbs/active"
    }),
    "symbols/carousel/description": getlist({
        Category: "typeStyles",
        Name: "symbols/carousel/description"
    }),
    "symbols/alert/6-info": getlist({
        Category: "typeStyles",
        Name: "symbols/alert/6-info"
    }),
    "symbols/pagination/disabled": getlist({
        Category: "typeStyles",
        Name: "symbols/pagination/disabled"
    }),
    "symbols/popover/title": getlist({
        Category: "typeStyles",
        Name: "symbols/popover/title"
    }),
    "symbols/progressbar-text": getlist({
        Category: "typeStyles",
        Name: "symbols/progressbar-text"
    }),
    "symbols/pagination/active": getlist({
        Category: "typeStyles",
        Name: "symbols/pagination/active"
    }),
    "symbols/navigation/tab/disabled": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/tab/disabled"
    }),
    "symbols/navigation/pill/active": getlist({
        Category: "typeStyles",
        Name: "symbols/navigation/pill/active"
    }),
    "symbols/accordion/header": getlist({
        Category: "typeStyles",
        Name: "symbols/accordion/header"
    }),
    "symbols/popover/body": getlist({
        Category: "typeStyles",
        Name: "symbols/popover/body"
    }),
    "symbols/table/header/dark": getlist({
        Category: "typeStyles",
        Name: "symbols/table/header/dark"
    }),

    "fonts/default/7-light": getlist({
        Category: "typeStyles",
        Name: "button fonts/default/7-light"
    }),
    "paragraph/tiny/light center": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/light center"
    }),
    "fonts/pill/7-light": getlist({
        Category: "typeStyles",
        Name: "button fonts/pill/7-light"
    }),
    "paragraph/tiny/lightleft": getlist({
        Category: "typeStyles",
        Name: "paragraph/tiny/light left"
    }),
    "Sub Text Tag/H7-blue-tag": getlist({
        Category: "typeStyles",
        Name: "Sub Text Tag/H7-blue-tag"
    }),
    // Fallback TypeStyles
    TypeStyles_FallBack: getlist({ Category: "typeStyles", Name: "FallBack" })
};

var mapping = Object.assign(mapping, local_style);
export { mapping };
