import React from "react";
import { mapping } from "../style/mapping";

import { Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-bootstrap/dist/all.css";

const bottomMargining = {
    marginBottom: "20px"
};
const errorMessageText = {
    position: "absolute",
    paddingTop: "2px",
    color: mapping["Color_Basic_Danger"]
};
const titleStyle = Object.assign(
    mapping["forms/label/1-default"],
    mapping["Form_Label_Padding"],
    {
        display: "flex",
        paddingBottom: "9px",
        color: mapping["Color_Basic_Light"]
    }
);

class CustomizedFieldInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            hint: this.props.hint,
            type: this.props.type,
            height: this.props.height,
            width: this.props.width
        };
    }

    render() {
        var FieldStyle = {
            height: this.state.height + "rem",
            width: this.props.width + "rem",
            borderWidth: "0px",
            backgroundColor: mapping["Field_BG_Color"],
            border: "none !important",
            //outline:mapping['Field_Outline']+' solid '+borderColor,
            paddingLeft: mapping["Field_padding_Horizontal"],
            paddingRight: mapping["Field_padding_Horizontal"],
            color: mapping["Color_Basic_Light"]
        };

        return (
            <div>
                {this.state.title ? (
                    <span
                        style={Object.assign(
                            {},
                            mapping["forms/input-default"],
                            mapping["Form_Label_Padding"],
                            { display: "flex", paddingBottom: "9px" }
                        )}
                    >
                        {this.state.title}
                    </span>
                ) : (
                    ""
                )}
                <Input
                    name={this.props.name}
                    className="inputplaceholder"
                    placeholder={this.state.hint}
                    style={Object.assign(
                        {},
                        mapping["forms/input-default"],
                        FieldStyle
                    )}
                    type={this.props.type}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

export { CustomizedFieldInput };
