import React from "react";
import "hammerjs";
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartAxisDefaults,
    ChartValueAxis,
    ChartValueAxisItem
} from "@progress/kendo-react-charts";

class BarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 16px Arial, sans-serif",
            background: "transparent",
            color: "white"
        };

        let configKey = Object.keys(this.props.data[0]);

        return (
            <div>
                <Chart
                    style={{ height: this.props.height }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea background={"transparent"} margin={20} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "9.93px sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                            labels={seriesLabels}
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

class StackBarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 16px Arial, sans-serif",
            background: "transparent",
            color: "white"
        };
        // console.log(this.props.data);
        let configKey = Object.keys(this.props.data[0][0]);

        return (
            <div>
                <Chart
                    style={{ height: this.props.height }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea background={"transparent"} margin={20} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "9.93px sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data[0]}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                            labels={seriesLabels}
                            stack={{ type: "100%" }}
                        />
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data[1]}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

export { BarChart, StackBarChart };
