import { mapping } from "./mapping";

var loginStyle = {
    formBgStyle: {
        height: "fit-content",
        width: "460px",
        backgroundColor: mapping["ComponentBGColor"],
        display: "inline-flex"
    },
    tileFieldPadding: {
        paddingTop: "41px"
    },
    fieldsPadding: {
        paddingTop: "10px"
    },
    fieldButtonPadding: {
        paddingTop: "20px"
    },
    formPadding: {
        paddingTop: "80px",
        paddingLeft: "75px"
    },
    backgroundImage: {
        height: "100vh",
        wdith: "100vw",
        backgroundPosition: "center" /* Center the image */,
        backgroundRepeat: "no-repeat" /* Do not repeat the image */,
        backgroundSize:
            "cover" /* Resize the background image to cover the entire container */
    },
    logo: {
        width: "540px",
        paddingTop: "150px",
        paddingLeft: "170px"
    }
};

export { loginStyle };
