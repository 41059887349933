import React from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { mapping } from "../style/mapping";

const titleStyle = Object.assign(
    mapping["forms/label/1-default"],
    mapping["Form_Label_Padding"],
    {
        display: "flex",
        paddingBottom: "9px",
        color: mapping["Color_Basic_Light"]
    }
);

const autoCompleteInputStyle = {
    ...mapping["forms/input-default"],
    width: "100%",
    border: "none",
    borderRadius: 0,
    backgroundColor: mapping["Color_Field_BG_Lighter"]
};

class CustomizedAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: this.props.value ? this.props.value : "",
            loading: false,
            opened: false
        };
    }

    itemRender = (li, itemProps) => {
        //console.log(li.props);
        const index = itemProps.dataItem.id;
        const itemChildren = (
            <span id={index}>
                {li.props.children} {index}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    onChange = event => {
        const value = event.target.value;
        let originalData = {
            opened: false,
            loading: false
        };
        let filterData = {
            opened: false,
            loading: true
        };
        const stateData = value.length < 3 ? originalData : filterData;

        const eventType = event.nativeEvent.type;
        const valueSelected =
            eventType === "click" ||
            (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        let filteredData = this.props.data.filter(data => {
            return data.includes(value) && data !== value;
        });
        let nextFilteredData = value === "" ? this.props.data : filteredData;

        if (valueSelected) {
            stateData.opened = false;
            if (this.props.valueSelectedChange) {
                this.props.valueSelectedChange({ value: value });
            }
        } else if (filteredData.length > 0) {
            stateData.opened = true;
        }

        this.setState({
            value: value,
            data: nextFilteredData,
            ...stateData
        });
        // for future customonchange
        if (this.props.onCustomChange) {
            this.props.onCustomChange({ value: value });
        }
        // for form onchange
        if (this.props.onChange) {
            this.props.onChange({ value: value });
        }
    };

    render() {
        return (
            <div>
                <span style={titleStyle}>{this.props.title}</span>
                <AutoComplete
                    style={
                        this.props.style
                            ? this.props.style
                            : autoCompleteInputStyle
                    }
                    name={this.props.name}
                    data={this.state.data}
                    value={this.state.value}
                    onChange={this.onChange}
                    opened={this.state.opened}
                    itemRender={this.itemRender}
                    onBlur={() => {
                        this.setState({ opened: false });
                    }}
                    onFocus={() => {
                        if (this.state.data.length > 0) {
                            this.setState({ opened: true });
                        }
                    }}
                />
            </div>
        );
    }
}

class SearchAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            loading: this.props.searching,
            opened: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.searching !== this.props.searching) {
            this.setState({
                loading: nextProps.searching,
                opened: !nextProps.searching
            });
        }

        let shouldUpdate =
            JSON.stringify(nextProps.data) !==
                JSON.stringify(this.props.data) && nextProps.data.length !== 0;
        if (shouldUpdate) {
            this.setState({ opened: true });
        } else if (nextProps.data.length === 0) {
            // if no result match, close dropdown
            this.setState({ opened: false });
        }
    }

    onChange = event => {
        const value = event.target.value;
        let stateData = {};
        if (value.length >= 3 && event.nativeEvent.type !== "click") {
            // this will update props.data and hence update this component
            this.props.handleSearchGroupRisk(value);
        } else {
            stateData = { opened: false };
        }

        // if the action is select from the drop down list, update value and close dropdown

        const eventType = event.nativeEvent.type;
        const valueSelected =
            eventType === "click" ||
            (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        const valueInsideOptions = this.props.data
            .map(a => a[this.props.filterKey])
            .includes(value);

        if (valueSelected && valueInsideOptions) {
            let riskID;
            if (event.nativeEvent.path[0].tagName === "SPAN") {
                riskID = parseInt(event.nativeEvent.path[0].getAttribute("id"));
            } else {
                riskID = parseInt(
                    event.nativeEvent.path[0].childNodes[0].getAttribute("id")
                );
            }
            this.props.handleriskDetailRequest(riskID);
            stateData = { opened: false };
        }

        this.setState({
            value: value,
            ...stateData
        });
        // for future customonchange
        if (this.props.CustomOnChange) {
            this.props.CustomOnChange({ value: value });
        }
        // for form onchange
        if (this.props.onChange) {
            this.props.onChange({ value: value });
        }
    };
    itemRender = (li, itemProps) => {
        const index = itemProps.dataItem.id;
        const itemChildren = (
            <span id={index}>
                {li.props.children} {index}
            </span>
        );

        return React.cloneElement(li, li.props, itemChildren);
    };

    render() {
        // console.log(this.props.data);
        return (
            <div>
                {this.props.title && (
                    <span style={titleStyle}>{this.props.title}</span>
                )}
                <AutoComplete
                    style={
                        this.props.inputFieldStyle
                            ? this.props.inputFieldStyle
                            : autoCompleteInputStyle
                    }
                    textField={this.props.filterKey}
                    name={this.props.name}
                    data={this.props.data}
                    value={this.state.value}
                    onChange={this.onChange}
                    loading={this.state.loading}
                    opened={this.state.opened}
                    itemRender={this.itemRender}
                    onBlur={() => {
                        this.setState({ opened: false });
                    }}
                    onFocus={() => {
                        if (this.props.data.length > 0) {
                            this.setState({ opened: true });
                        }
                    }}
                />
            </div>
        );
    }
}

class NonRiskSearchAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            loading: this.props.searching,
            opened: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.searching !== this.props.searching) {
            this.setState({
                loading: nextProps.searching,
                opened: !nextProps.searching
            });
        }

        let shouldUpdate =
            JSON.stringify(nextProps.data) !==
                JSON.stringify(this.props.data) && nextProps.data.length !== 0;
        if (shouldUpdate) {
            this.setState({ opened: true });
        } else if (nextProps.data.length === 0) {
            // if no result match, close dropdown
            this.setState({ opened: false });
        }
    }

    onChange = event => {
        const value = event.target.value;
        let stateData = {};
        if (value.length >= 3 && event.nativeEvent.type !== "click") {
            // this will update props.data and hence update this component
            stateData = { opened: true };
        } else {
            stateData = { opened: false };
        }

        // if the action is select from the drop down list, update value and close dropdown

        const eventType = event.nativeEvent.type;
        const valueSelected =
            eventType === "click" ||
            (eventType === "keydown" && event.nativeEvent.keyCode === 13);

        const valueInsideOptions = this.props.data
            .map(a => a[this.props.filterKey])
            .includes(value);

        if (valueSelected && valueInsideOptions) {
            let riskID;
            if (event.nativeEvent.path[0].tagName === "SPAN") {
                riskID = parseInt(event.nativeEvent.path[0].getAttribute("id"));
            } else {
                riskID = parseInt(
                    event.nativeEvent.path[0].childNodes[0].getAttribute("id")
                );
            }
            this.props.handleriskDetailRequest(riskID);
            stateData = { opened: false };
        }

        this.setState({
            value: value,
            ...stateData
        });
        // for future customonchange
        if (this.props.CustomOnChange) {
            this.props.CustomOnChange({ value: value });
        }
        // for form onchange
        if (this.props.onChange) {
            this.props.onChange({ value: value });
        }
    };
    itemRender = (li, itemProps) => {
        const index = itemProps.dataItem.id;
        const itemChildren = (
            <span id={index}>
                {li.props.children} {index}
            </span>
        );

        return React.cloneElement(li, li.props, itemChildren);
    };

    render() {
        console.log(this.props.data);
        return (
            <div>
                {this.props.title && (
                    <span style={titleStyle}>{this.props.title}</span>
                )}
                <AutoComplete
                    style={
                        this.props.inputFieldStyle
                            ? this.props.inputFieldStyle
                            : autoCompleteInputStyle
                    }
                    textField={this.props.filterKey}
                    name={this.props.name}
                    data={this.props.data}
                    value={this.state.value}
                    onChange={this.onChange}
                    loading={this.state.loading}
                    opened={this.state.opened}
                    itemRender={this.itemRender}
                    onBlur={() => {
                        this.setState({ opened: false });
                    }}
                    onFocus={() => {
                        if (
                            this.props.data.length > 0 &&
                            this.state.value.length >= 3
                        ) {
                            this.setState({ opened: true });
                        }
                    }}
                />
            </div>
        );
    }
}

export {
    CustomizedAutoComplete,
    SearchAutoComplete,
    NonRiskSearchAutoComplete
};
