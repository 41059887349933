import React from "react";

import ArchiveIconSVG from "../icon/Google Materials Icons/content/archive_24px.svg";
import AddCircleOutline from "../icon/Google Materials Icons/content/add_circle_outline_24px.svg";
import HomeIcon from "../icon/Google Materials Icons/action/home_24px.svg";
import DeleteIcon from "../icon/Google Materials Icons/action/delete_24px.svg";
import CalendarIcon from "../icon/Google Materials Icons/action/date_range_24px.svg";
import WorkOutlineIcon from "../icon/Google Materials Icons/action/work_outline_24px.svg";
import MoreHoriIcon from "../icon/Google Materials Icons/navigation/more_horiz_24px.svg";
import VersionCommitActive from "../icon/Customize Icon/commit_active.svg";
import VersionCommitInactive from "../icon/Customize Icon/commit_inactive.svg";
import VersionBranchActive from "../icon/Customize Icon/branch_active.svg";
import VersionBranchInactive from "../icon/Customize Icon/branch_inactive.svg";
import ListNumber from "../icon/Google Materials Icons/editor/format_list_numbered_24px.svg";
import BarChart from "../icon/Google Materials Icons/editor/bar_chart_24px.svg";
import File from "../icon/Google Materials Icons/action/description-24px.svg";
import Info from "../icon/Google Materials Icons/action/info-24px.svg";
import Cancel from "../icon/Google Materials Icons/navigation/cancel_24px.svg";
import Speaker from "../icon/Google Materials Icons/action/speaker_notes_24px.svg";
import Warning from "../icon/Google Materials Icons/alert/warning_amber_24px.svg";
import People from "../icon/Google Materials Icons/social/people_outline_24px.svg";
import Comment from "../icon/Google Materials Icons/communication/comment_24px.svg";
import Swap from "../icon/Google Materials Icons/action/swap_horizontal_circle_24px.svg";
import Send from "../icon/Google Materials Icons/content/send_24px.svg";
import Edit from "../icon/Google Materials Icons/image/edit_24px.svg";
import Search from "../icon/Google Materials Icons/action/search_24px.svg";
import Flip from "../icon/Google Materials Icons/notification/sync_24px.svg";

class IconSVG extends React.Component {
    render() {
        const iconWrapperStyle = {
            transform: "scale(" + this.props.size + ")",
            transformOrigin: "50% 50% 0px",
            display: "inline-flex",
            ...this.props.wrapperStyle
        };
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div style={iconWrapperStyle} onClick={this.props.onClick}>
                {this.props.name === "archive" && (
                    <ArchiveIconSVG
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "newRisk" && (
                    <AddCircleOutline
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "home" && (
                    <HomeIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "delete" && (
                    <DeleteIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "calendar" && (
                    <CalendarIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "work" && (
                    <WorkOutlineIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "moreHori" && (
                    <MoreHoriIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "commitActive" && (
                    <VersionCommitActive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "commitInactive" && (
                    <VersionCommitInactive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "branchActive" && (
                    <VersionBranchActive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "branchInactive" && (
                    <VersionBranchInactive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "listNumber" && (
                    <ListNumber
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "barChart" && (
                    <BarChart
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "file" && (
                    <File style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "info" && (
                    <Info style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "cancel" && (
                    <Cancel
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "speaker" && (
                    <Speaker
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "warning" && (
                    <Warning
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "people" && (
                    <People
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "comment" && (
                    <Comment
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "swap" && (
                    <Swap style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "send" && (
                    <Send style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "edit" && (
                    <Edit style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "search" && (
                    <Search
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "flip" && (
                    <Flip style={{ margin: "auto" }} fill={this.props.color} />
                )}
            </div>
        );
    }
}

export { IconSVG };
