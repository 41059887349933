import React from "react";
import { mapping } from "../style/mapping";
import { RiskTile } from "./RiskTile";
import "@progress/kendo-theme-bootstrap/dist/all.css";

const RiskTowerActiveBGColor = mapping["Color_Risk_Tower_Active"];
const RiskTowerInactiveBGColor =
    mapping["Color_Risk_Tower_Active"] +
    mapping["Color_Risk_Tower_Inactive_Transparent"];

const TotalRiskIcon = props => {
    const outlineStyle = {
        width: "24px",
        height: "24px",
        borderRadius: "4px",
        backgroundColor: mapping["Color_Extra_Transparent"],
        border: "1px solid #9EB7C5"
    };
    return (
        <div style={outlineStyle}>
            <div style={mapping["paragraph/small/lightcenter"]}>
                {props.totalNumber}
            </div>
        </div>
    );
};

class RiskTower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: this.props.category,
            riskList: this.props.riskList,
            hover: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            riskList: nextProps.riskList
        };
    }

    render() {
        var RiskTowerStyle = {
            height: "820px",
            width: "220px",
            borderRadius: "10px"
        };

        const RiskTowerHeaderStyle = {
            height: "70px",
            width: "100%",
            position: "relative"
        };

        const RiskTowerHeaderTextStyle = {
            paddingLeft: "12px",
            paddingTop: "17px",
            display: "inline-flex"
        };

        const RiskTowerNumberTextStyle = {
            display: "inline-flex",
            position: "absolute",
            right: "12px",
            top: "14px"
        };

        // if the risk list is empty, is should be inactive
        if (!this.state.riskList.length) {
            RiskTowerStyle["backgroundColor"] = RiskTowerInactiveBGColor;
        } else {
            RiskTowerStyle["backgroundColor"] = RiskTowerActiveBGColor;
        }

        const onHover = () => {
            this.setState({ hover: true });
        };

        const onUnhover = () => {
            this.setState({ hover: false });
        };

        const riskTowerHoverEffectStyle = Object.assign({}, RiskTowerStyle, {
            boxShadow: mapping["Color_Risk_Tower_Hover_Shadow"]
        });
        return (
            <div
                style={
                    this.state.hover && this.state.riskList.length
                        ? riskTowerHoverEffectStyle
                        : RiskTowerStyle
                }
                onMouseEnter={onHover}
                onMouseLeave={onUnhover}
            >
                {/* Tower header */}
                <div style={RiskTowerHeaderStyle}>
                    {/* Tower title text */}
                    <div
                        style={Object.assign(
                            {},
                            RiskTowerHeaderTextStyle,
                            mapping["heading/h5/lightleft"]
                        )}
                    >
                        {this.state.category}
                    </div>

                    {/* Tower header total risk count */}
                    <div style={RiskTowerNumberTextStyle}>
                        <TotalRiskIcon
                            totalNumber={this.state.riskList.length}
                        />
                    </div>
                </div>

                <div>
                    {this.state.riskList.map(risk => {
                        return (
                            <div style={{ paddingBottom: "20px" }}>
                                <RiskTile
                                    risk={risk}
                                    onHoverCallback={this.props.onHoverCallback}
                                    onUnhoverCallback={
                                        this.props.onUnhoverCallback
                                    }
                                    RiskTileClick={this.props.RiskTileClick}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export { RiskTower };
