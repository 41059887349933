import * as React from "react";
import { mapping } from "../style/mapping";
import { RadioGroup } from "@progress/kendo-react-inputs";

class RadioButtonGroup extends React.Component {
    constructor() {
        super();
        this.state = {};
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.populate_id !== this.props.populate_id) {
            this.setState({ value: nextProps.value });
            this.props.onChange({ value: nextProps.value });
        }
    }
    render() {
        const defaultFontStyle = Object.assign(
            {},
            mapping["forms/label/1-default"],
            { color: "white", display: "block" }
        );
        var fontStyle = this.props.fontStyle
            ? this.props.fontStyle
            : defaultFontStyle;

        const onChange = event => {
            this.setState({ value: event.value });
            // for future customOnchange
            if (this.props.CustomOnChange) {
                this.props.CustomOnChange(event);
            }
            // for form onchange
            if (this.props.onChange) {
                this.props.onChange({ value: event.value });
            }
        };

        return (
            <div>
                <RadioGroup
                    defaultValue={
                        this.props.defaultValue
                            ? this.props.defaultValue
                            : this.props.data[0].value
                    }
                    style={fontStyle}
                    data={this.props.data}
                    layout={
                        this.props.layout ? this.props.layout : "horizontal"
                    }
                    onChange={onChange}
                    value={this.state.value}
                    disabled={this.props.disabled}
                />
                <style>
                    {`.k-radio.task { background: white; border-color: grey; box-shadow: none; }
                .k-radio.blue { background-color:rgba(255,255,255,0.11); border:solid 1px #00BEFF; }
                .k-radio.blue:checked { background-color:#00BEFF; color: white; }`}
                </style>
            </div>
        );
    }
}

export { RadioButtonGroup };
