import React from "react";
import ReactLoading from "react-loading";

class Loading extends React.Component {
    render() {
        return (
            <div style={RiskCreationFormWrapStyle}>
                <div style={RiskCreationFormStyle}>
                    <ReactLoading
                        type={this.props.type}
                        color={this.props.color}
                        height={this.props.height}
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    }
}

const RiskCreationFormStyle = {
    position: "relative",
    left: "-50%"
};
const RiskCreationFormWrapStyle = {
    position: "fixed",
    left: "50%",
    top: "50%"
};
export { Loading };
